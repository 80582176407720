import styled from "styled-components"

const SmallHeader = styled.h4`
  font-size: ${props => props.theme.font.desktop.h4.size};
  font-weight: ${props =>(props.bold ? props.theme.font.desktop.h4.weight.bold : props.theme.font.desktop.h4.weight.regular )};
  color: ${props => (props.color ? props.color : props.theme.color.black)};
  line-height: 1.2;
`

export { SmallHeader }
