import React from "react"
import PropTypes from "prop-types"
import SEO from "components/seo"

import { ThemeProvider } from "styled-components"
import { defaultTheme } from "shared/Themes"

import { ParallaxProvider } from "react-scroll-parallax"

import { GlobalWrapper } from "shared/Styles/Wrappers"

import SwiperCore, {
  EffectFade,
  Navigation,
  Autoplay,
  Pagination,
} from "swiper"

import { Navbar } from "../shared/Navbar"
import { Footer } from "../shared/Footer"

import "shared/Styles/global.css"
import "shared/Styles/reset.css"

import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/effect-fade/effect-fade.scss"

SwiperCore.use([EffectFade, Navigation, Autoplay, Pagination])

const MainLayout = ({ children, pageContext }) => {
  if (pageContext.layout === "404") {
    return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
  } else {
    return (
      <>
        <ParallaxProvider>
          <ThemeProvider theme={defaultTheme}>
            <Navbar />
            <GlobalWrapper>{children}</GlobalWrapper>
            <Footer disableContact={pageContext.disableContact} />
          </ThemeProvider>
        </ParallaxProvider>
      </>
    )
  }
}

MainLayout.propTypes = {
  children: PropTypes.node,
}

export default MainLayout
