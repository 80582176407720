import styled from "styled-components"

const SectionTitle = styled.h2`
  font-size: ${props => props.theme.font.desktop.h3.size};
  font-weight: ${props => props.theme.font.desktop.h3.weight.regular};
  color: ${props => (props.color ? props.color : props.theme.color.black)};
  line-height: 1.3;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 13px;
    left: -90px;
    height: 2px;
    width: 47px;
    background-color: ${props =>
      props.color ? props.color : props.theme.color.black};
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    padding-left: 60px;
    &:before {
      top: 10px;
      left: 0;
      width: 35px;
    }
    font-size: 24px;
  }
`
export { SectionTitle }
