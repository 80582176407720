import React, { useState } from "react"
import {
  Hamburger,
  Logo,
  NavbarBox,
  NavbarWrapper,
  MenuItem,
  MenuList,
  MenuRight,
  MenuSocials,
  MenuWrapper,
  SubmenuWrapper,
  SubmenuItem,
} from "./styles.js"

import { MenuLink } from "components/Buttons"

import LogoImage from "assets/logo.png"
import FbLogo from "assets/fb.svg"
import { useScrollPosition } from "shared/Hooks/useScrollPosition"

const hrefs = [
  {
    title: "Start",
    slug: "/",
  },
  {
    title: "O firmie",
    slug: "/o-firmie",
  },
  {
    title: "Oferta",
    slug: "/oferta",
    submenu: [
      {
        title: "Doradztwo",
        slug: "/oferta#doradztwo",
      },
      {
        title: "Montaż",
        slug: "/oferta#montaz",
      },
      {
        title: "Serwis",
        slug: "/oferta#serwis",
      },
    ],
  },
  {
    title: "Realizacje",
    slug: "/realizacje",
  },
  {
    title: "Kontakt",
    slug: "/kontakt",
  },
]

const socials = [
  {
    title: "facebook",
    slug: "https://www.facebook.com/klimalabkrk",
    icon: (
      <svg
        width="8"
        height="17"
        viewBox="0 0 6 13"
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.82979 0.0485075L4.17021 0C3.53191 0 2.89362 0.242537 2.42553 0.776119C1.91489 1.3097 1.65957 2.03731 1.65957 2.76493V4.5597H0.12766C0.0425532 4.60821 0 4.65672 0 4.70522V6.98507C0 7.03358 0.0425532 7.1306 0.12766 7.1306H1.65957V12.8545C1.65957 12.903 1.70213 13 1.78723 13H3.82979C3.87234 13 3.95745 12.9515 3.95745 12.8545V7.1306H5.53191C5.57447 7.1306 5.65957 7.08209 5.65957 7.03358L5.91489 4.75373C5.91489 4.70522 5.91489 4.65672 5.87234 4.65672C5.82979 4.60821 5.82979 4.60821 5.78723 4.60821H3.95745V3.44403C3.95745 3.44403 3.91489 2.86194 4.17021 2.5709C4.29787 2.42537 4.46808 2.32836 4.68085 2.32836H5.87234C5.91489 2.32836 6 2.27985 6 2.18284V0.19403C5.95745 0.0970149 5.91489 0.0485075 5.82979 0.0485075Z"
          fill="#fff"
        />
      </svg>
    ),
  },
]

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuScrolled, setMenuScrolled] = useState(false)

  const handleClick = () => {
    setMenuOpen(!menuOpen)

    document.querySelector("body").classList.toggle("blocked")
  }
  const handleCloseMenu = () => {
    if (window.innerWidth < 1025) {
      setMenuOpen(false)
      document.querySelector("body").classList.toggle("blocked")
    }
  }

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -120) {
      setMenuScrolled(true)
    } else {
      setMenuScrolled(false)
    }
  })

  return (
    <NavbarBox className={menuScrolled ? "active" : ""}>
      <NavbarWrapper>
        <Logo href="/">
          <img src={LogoImage} alt="" />
        </Logo>
        <MenuRight>
          <MenuWrapper className={menuOpen ? "active" : ""}>
            <MenuList>
              {hrefs.map((href, i) => (
                <MenuItem key={i} onClick={() => handleCloseMenu()}>
                  <MenuLink activeClassName="current" to={href.slug}>
                    {href.title}
                  </MenuLink>
                  {href.submenu && (
                    <SubmenuWrapper>
                      {href.submenu.map((submenu_item, i) => (
                        <SubmenuItem
                          key={`${submenu_item.title}_${i}`}
                          to={submenu_item.slug}
                        >
                          {submenu_item.title}
                        </SubmenuItem>
                      ))}
                    </SubmenuWrapper>
                  )}
                </MenuItem>
              ))}
            </MenuList>
          </MenuWrapper>
          <MenuSocials>
            <MenuList>
              {socials.map((social, i) => (
                <MenuItem key={i}>
                  <a href={social.slug}>{social.icon}</a>
                </MenuItem>
              ))}
            </MenuList>
          </MenuSocials>
          <Hamburger
            className={menuOpen ? "active" : ""}
            onClick={() => handleClick()}
          >
            <div>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Hamburger>
        </MenuRight>
      </NavbarWrapper>
    </NavbarBox>
  )
}

export { Navbar }
