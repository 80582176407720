import styled from "styled-components"
import { Link } from "gatsby"
import menuBg from "../../assets/menu.png"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export const Logo = styled.a`
  display: inline-block;
  width: 230px;
  transition: 0.5s width ease-in-out;
  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    width: 180px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    width: 160px !important;
  }
`
export const NavbarBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 25;
  height: 120px;
  transition: 0.4s all ease;
  display: flex;
  align-items: center;

  &.active {
    height: 70px;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    ul {
      li {
        a {
          color: ${props => props.theme.color.primary};
          path {
            fill: ${p => p.theme.color.primary};
          }
          @media screen and (max-width: ${props =>
              props.theme.breakpoints.tablet}) {
            color: #fff !important;
          }
          &:hover {
            color: ${props => props.theme.color.secondary};
          }
        }
      }
    }
    @media screen and (max-width: ${props =>
        props.theme.breakpoints.laptopStandard}) {
      height: 50px;
      ${Logo} {
        width: 180px;
      }
    }
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0%;
    width: 35vw;
    height: 100%;
    background-color: #fff;
    z-index: 0;
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      width: 100%;
    }
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    height: 80px !important;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    height: 60px !important;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    height: 50px !important;
  }
`
export const NavbarWrapper = styled.div`
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.4s all ease;
  position: relative;
  z-index: 2;
`
export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 3.125vw;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    position: fixed;
    top: 60px;
    left: 0;
    padding: 40px 20px 80px 20px;
    width: 100vw;
    height: calc(100vh - 60px);
    flex-direction: column;
    align-items: flex-start;
    background-color: ${props => props.theme.color.primary};
    background-image: url(${menuBg});
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 70%;
    transform: translateX(-120%);
    transition: 0.4s all ease-in-out;
    &.active {
      transform: translateX(0%);
    }
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    top: 50px;
    height: calc(100vh - 50px);
  }
  @media screen and (max-width: 650px) {
    background-size: cover;
    background-position: top right;
  }
`

export const MenuOptions = styled.div`
  display: flex;
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`

export const MenuSocials = styled.div`
  margin-left: 30px;
  ul {
    flex-direction: row !important;
    li {
      margin: 0 10px;
      a {
        font-size: ${props => props.theme.font.desktop.link.size} !important;
        &:after {
          display: none;
        }
        svg {
          path {
            transition: 0.4s all ease;
            /* fill: ${props => props.theme.color.primary}; */
          }
        }
        &:hover {
          svg {
            fill: ${props => props.theme.color.secondary};

            path {
              fill: ${props => props.theme.color.secondary};
            }
          }
        }
      }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      justify-content: center;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    margin-left: 0;
  }
`

export const MenuList = styled.ul`
  display: flex;
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const SubmenuWrapper = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 150px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s all ease;
  &:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: ${props => props.theme.color.primary};
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    opacity: 1;
    visibility: visible;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 60px);
    display: flex;
    flex-direction: row;
    background-color: transparent;
    width: auto;
    align-items: center;
    &:before {
      display: none;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phoneBig}) {
    position: relative;
    left: auto;
    top: 0%;
    transform: none;
    margin-top: 10px;
  }
`

export const SubmenuItem = styled(AnchorLink)`
  padding: 15px 0;
  display: inline-block;
  color: #727679;
  text-decoration: none;
  transition: 0.4s all ease;
  font-size: 16px;
  &:hover {
    color: ${props => props.theme.color.primary};
  }
  &:not(:last-child) {
    border-bottom: 1px solid #e9e9e9;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 20px;
    color: #fff;

    &:not(:last-child) {
      border-bottom: none;
      margin-right: 30px;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phoneBig}) {
    padding: 10px 0;
  }
`

export const MenuItem = styled.li`
  margin: 0 20px;
  position: relative;
  &:hover {
    ${SubmenuWrapper} {
      opacity: 1;
      visibility: visible;
    }
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    margin: 0 13px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    margin-bottom: 15px;
  }
`

export const Hamburger = styled.div`
  padding: 10px;
  cursor: pointer;
  display: none;
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: block;
  }
  div {
    width: 24px;
    height: 18px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.4s all ease;
    span {
      height: 4px;
      width: 100%;
      display: block;
      background-color: ${props => props.theme.color.primary};
      transition: 0.4s all ease;
    }
  }
  &.active {
    div {
      transform: rotate(45deg);
      span:nth-child(1) {
        transform: rotate(90deg) translateX(7px);
      }
      span:nth-child(3) {
        opacity: 0;
      }
    }
  }
`

export const MenuRight = styled.div`
  display: flex;
  align-items: center;
`
