import React from "react"
import { IconWrapper, IconPathStroke } from "shared/Styles/Icons"

const Valuation = ({ width, height, color }) => {
  return (
    <IconWrapper
      width={width}
      height={height}
      viewBox="0 0 125 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPathStroke
        d="M62.3695 2.59521C91.0751 2.59521 114.562 25.9524 114.562 54.5V57.0952H119.781L106.733 75.2619L96.2943 57.0952H101.514V54.5C101.514 32.9595 84.0292 15.5714 62.3695 15.5714C48.5386 15.5714 36.2735 22.8381 29.2275 33.7381"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M36.2734 9.60229C34.4467 10.6404 32.62 11.938 31.0542 12.9761"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M46.4509 4.93091C44.6242 5.44996 43.0584 6.22853 41.4927 6.74757"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M57.1501 2.85474C55.5844 3.11426 53.7577 3.37378 52.1919 3.63331"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M62.3695 106.405C33.6639 106.405 10.4384 83.0476 10.4384 54.4999V51.9047H5.21924L18.2673 33.738L28.7057 51.9047H23.4865V54.4999C23.4865 76.0404 40.4489 93.4285 62.1086 93.4285C75.9395 93.4285 88.2046 86.1618 95.2505 75.2618"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M88.4658 99.3976C90.2925 98.3595 92.1193 97.0619 93.685 96.0238"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M78.2881 104.069C80.1148 103.55 81.6806 102.771 83.2463 102.252"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M67.5889 106.145C69.1546 105.886 70.9813 105.626 72.5471 105.367"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M84.5513 37.8904L75.4177 46.9737L67.8498 39.707L76.9834 30.6237C73.33 26.9904 65.2402 27.5094 60.282 32.4404C56.6285 36.0737 54.8018 41.0046 57.1504 46.1951C57.9333 48.0118 57.4114 50.088 55.8456 51.6451L40.188 67.2166C38.3613 69.0332 37.8394 71.888 39.4051 73.9642C41.2319 76.5594 45.1463 76.8189 47.234 74.7427L63.1525 58.9118C64.4573 57.6142 66.545 56.8356 68.6327 57.6142C73.8519 59.6904 79.0711 57.8737 82.4636 54.4999C87.4218 49.5689 88.2047 41.7832 84.5513 37.8904Z"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
        stroke-linejoin="round"
      />
    </IconWrapper>
  )
}

export { Valuation }
