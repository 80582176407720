import React from "react"
import { IconWrapper, IconPathStroke } from "shared/Styles/Icons"

const Experience = ({ width, height, color }) => {
  return (
    <IconWrapper
      width={width}
      height={height}
      viewBox="0 0 93 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPathStroke
        d="M74.6314 34.3651V5.24037C74.6314 3.89615 73.736 3 72.3929 3H16.4309L3 16.4422V99.3358C3 100.68 3.89539 101.576 5.23848 101.576H72.3929C73.736 101.576 74.6314 100.68 74.6314 99.3358V72.4514"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M7.47705 20.9226H20.908V7.48071"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M88.5102 46.9115L80.6756 54.7527L74.4078 48.4797L82.2425 40.6384C79.1086 37.5019 72.1693 38.174 67.9162 42.2067C64.3346 45.7913 63.2154 50.7201 64.5585 55.2008L39.2637 80.2929C36.5775 82.9813 36.5775 87.238 39.2637 89.7024C41.9498 92.3909 46.2029 92.3909 48.6653 89.7024L73.7363 64.6103C78.2132 65.7305 83.1379 64.6103 86.7195 61.2498C90.9726 57.2171 91.6441 50.272 88.5102 46.9115Z"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
        stroke-linejoin="round"
      />
      <IconPathStroke
        d="M18.6694 34.3655H54.4851"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M18.6694 43.3267H54.4851"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M18.6694 52.2881H38.8158"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
    </IconWrapper>
  )
}

export { Experience }
