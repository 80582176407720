import styled from "styled-components"

const NormalHeader = styled.h3`
  font-size: ${props => props.theme.font.desktop.h3.size};
  font-weight: ${props =>
    props.bolded
      ? props.theme.font.desktop.h3.weight.bold
      : props.theme.font.desktop.h3.weight.regular};
  color: ${props => (props.color ? props.color : props.theme.color.black)};
  line-height: 1.3;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptopSmall}) {
    font-size: ${({fontSize})=>(fontSize?fontSize:'24px')};
  }
`

export { NormalHeader }
