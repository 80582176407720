import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export const ButtonCommon = css`
  color: ${props => (props.hovercolor ? props.hovercolor : "white")};
  background: ${({ theme }) => theme.color.secondary};
  min-width: 220px;
  font-weight: bold;
  font-size: ${props => (props.fontSize ? props.fontSize : "20px")};
  line-height: 1.2;
  border: none;
  transition: 0.3s box-shadow ease-in-out;
  cursor: pointer;
  padding: 14px 20px;
  display: inline-block;
  text-align: center;
  text-decoration: none;

  &:hover {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopStandard}) {
    font-size: 18px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    min-width: 196px;
    font-size: ${props => (props.fontSize ? props.fontSize : "20px")};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    min-width: 146px;
    font-size: ${props => (props.fontSize ? props.fontSize : "16px")};
  }
`
export const OrangeButton = styled.button`
    ${ButtonCommon}
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}){
        padding-top: 8px ;
        padding-bottom: 8px;
    }
`
export const OrangeLinkButton = styled(Link)`
  ${ButtonCommon}
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
  }
`

export const LinkCommon = css`
  color: ${props => (props.color ? props.color : "white")};
  text-decoration: none;
  font-size: ${props =>
    props.fontSize ? props.fontSize : props.theme.font.desktop.link.size};
  font-weight: 600;
  transition: 0.3s color ease-in-out;
  &:hover {
    color: ${props =>
      props.hovercolor ? props.hovercolor : props.theme.color.primary};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  }
`
export const MenuLink = styled(Link)`
  ${LinkCommon}
  position:relative;
  overflow: hidden;
  white-space: nowrap;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 30px;
  }

  &.current {
    color: ${({ theme }) => theme.color.secondary} !important;
  }
  &:hover {
    color: ${props =>
      props.hovercolor ? props.hovercolor : props.theme.color.secondary};
  }
`
export const FooterLink = styled(AnchorLink)`
  ${LinkCommon}
`
