import React from "react"
import { IconWrapper, IconPath } from "shared/Styles/Icons"

const Selection = ({ width, height, color }) => {
  return (
    <IconWrapper
      width="105"
      height="105"
      viewBox="0 0 105 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPath
        d="M25.7143 78.2143V95.3571C25.7143 97.1323 24.2751 98.5714 22.5 98.5714C20.7249 98.5714 19.2857 97.1323 19.2857 95.3571V78.2143C19.2857 76.4391 20.7249 75 22.5 75C24.2751 75 25.7143 76.4391 25.7143 78.2143ZM35.3571 75C33.582 75 32.1429 76.4391 32.1429 78.2143V95.3571C32.1429 97.1323 33.582 98.5714 35.3571 98.5714C37.1323 98.5714 38.5714 97.1323 38.5714 95.3571V78.2143C38.5714 76.4391 37.1323 75 35.3571 75ZM105 13.9286V54.6429C105 61.9626 99.3244 67.9817 92.1429 68.5307V96.4286C92.1429 101.155 88.2977 105 83.5714 105H68.5714C63.8451 105 60 101.155 60 96.4286V68.5714H13.9286C6.24836 68.5714 0 62.3231 0 54.6429V13.9286C0 6.24836 6.24836 0 13.9286 0H91.0714C98.7516 0 105 6.24836 105 13.9286ZM68.5714 49.2857H79.2857V40.7143C79.2857 39.5327 78.3244 38.5714 77.1429 38.5714H27.8571C26.6756 38.5714 25.7143 39.5327 25.7143 40.7143V62.1429H60V57.8571C60 53.1309 63.8451 49.2857 68.5714 49.2857ZM85.7143 92.1429H66.4286V96.4286C66.4286 97.6101 67.3899 98.5714 68.5714 98.5714H83.5714C84.753 98.5714 85.7143 97.6101 85.7143 96.4286V92.1429ZM85.7143 57.8571C85.7143 56.6756 84.753 55.7143 83.5714 55.7143H79.2857V56.7857C79.2857 58.5609 77.8466 60 76.0714 60C74.2963 60 72.8571 58.5609 72.8571 56.7857V55.7143H68.5714C67.3899 55.7143 66.4286 56.6756 66.4286 57.8571V85.7143H85.7143V57.8571ZM98.5714 13.9286C98.5714 9.79307 95.2069 6.42857 91.0714 6.42857H13.9286C9.79307 6.42857 6.42857 9.79307 6.42857 13.9286V54.6429C6.42857 58.7784 9.79307 62.1429 13.9286 62.1429H19.2857V40.7143C19.2857 35.988 23.1309 32.1429 27.8571 32.1429H77.1429C81.8691 32.1429 85.7143 35.988 85.7143 40.7143V49.557C89.4071 50.5108 92.1429 53.8706 92.1429 57.8571V62.0666C95.7726 61.545 98.5714 58.4147 98.5714 54.6429V13.9286ZM48.2143 75C46.4391 75 45 76.4391 45 78.2143V95.3571C45 97.1323 46.4391 98.5714 48.2143 98.5714C49.9894 98.5714 51.4286 97.1323 51.4286 95.3571V78.2143C51.4286 76.4391 49.9894 75 48.2143 75Z"
        color="#1371FB"
      />
    </IconWrapper>
  )
}
export { Selection }
