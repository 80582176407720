import React from "react"
import { IconWrapper, IconPath } from "shared/Styles/Icons"

const Quote = ({ width, height, color }) => {
  return (
    <IconWrapper
      width={width}
      height={height}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPath
        d="M13.376 0.333332V5.19911L11.8649 10.8507H9.02397L10.1422 5.864H7.60352V0.333332H13.376ZM5.88086 0.333332V5.19911L4.36975 10.8507H1.52886L2.64708 5.864H0.108414V0.333332H5.88086Z"
        color={color}
      />
    </IconWrapper>
  )
}
export { Quote }
