/*global google*/
import React, { Component } from "react"
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"

export class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        initialCenter={{
          lat: 50.0381327,
          lng: 20.0041406,
        }}
      >
        <Marker
          name={"Klimalab"}
          position={{ lat: 50.009173841162294, lng: 20.069493017019358 }}
          icon={{
            url: "/marker.png",
            anchor: new google.maps.Point(70, 130),
            scaledSize: new google.maps.Size(140, 159),
          }}
        />
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: `AIzaSyCCBRt4wtudAqa1AHG-r-a3D4mtdVswMXA`,
})(MapContainer)
