import React from "react"
import { IconWrapper, IconPath } from "shared/Styles/Icons"

const Benefits = ({ width, height, color }) => {
  return (
    <IconWrapper
      width="99"
      height="99"
      viewBox="0 0 99 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPath
        d="M76.8096 59.0203C74.2034 56.4126 70.0403 56.5039 67.5527 58.9927C64.682 61.8644 58.1821 68.3649 58.1801 68.3671L30.6536 40.8261C32.0465 39.434 33.8091 37.6729 39.994 31.4813C42.5564 28.9215 42.5666 24.7622 39.9954 22.1895C23.6787 5.8657 24.6878 6.86918 24.5049 6.70262C21.918 4.34577 17.9853 4.3857 15.2367 7.13588C15.1987 7.17387 10.3573 11.9976 10.3331 12.0458C-9.96593 32.0757 2.56802 56.0995 22.7413 76.2818C41.8692 95.4224 64.9404 108.369 85.0934 90.469C86.334 89.3664 92.1963 83.4737 92.5139 83.0884C92.5187 83.0823 92.5236 83.0765 92.5285 83.0704C94.6535 80.4496 94.4565 76.6731 92.0703 74.2857L76.8096 59.0203ZM19.3673 11.2685C19.7589 10.8767 20.211 10.661 20.6057 11.0567L35.8645 26.3221C36.1532 26.611 36.14 27.0721 35.8639 27.3482C34.4872 28.7265 33.5593 29.6551 32.8291 30.3859L16.5391 14.0884C17.7138 12.9135 18.823 11.813 19.3673 11.2685ZM26.872 72.1494C10.679 55.9489 -2.5046 35.5921 12.4823 18.295L28.6987 34.5187C27.6057 35.6112 28.9678 34.2496 26.0655 37.151C24.0261 39.1911 24.0432 42.4824 26.0655 44.5011L54.506 72.9565C56.5319 74.9835 59.8287 74.9835 61.8556 72.9557C63.2447 71.5649 64.0274 70.7814 64.4863 70.3222L80.7025 86.5457C63.47 101.494 43.1667 88.4549 26.872 72.1494ZM87.961 79.4079C87.1439 80.247 85.7261 81.6685 84.9077 82.4874L68.619 66.1914L71.6838 63.1254C71.9167 62.8924 72.3875 62.8615 72.6792 63.1531L87.9397 78.4185C88.2011 78.6801 88.2406 79.1198 87.961 79.4079Z"
        color="#1371FB"
      />
      <IconPath
        d="M67.8629 16H85.1371C86.7182 16 88 14.6568 88 13C88 11.3432 86.7182 10 85.1371 10H67.8629C66.2818 10 65 11.3432 65 13C65 14.6568 66.2816 16 67.8629 16Z"
        color="#1371FB"
      />
      <IconPath
        d="M67.8629 26H85.1371C86.7182 26 88 24.6568 88 23C88 21.3432 86.7182 20 85.1371 20H67.8629C66.2818 20 65 21.3432 65 23C65 24.6568 66.2816 26 67.8629 26Z"
        color="#1371FB"
      />
      <IconPath
        d="M94.5786 0H58.5325C56.0946 0 54.1111 2.00573 54.1111 4.47138V32.0091L47.8482 38.3422C46.2511 39.9573 47.0227 42.7219 49.2152 43.2571L53.025 44.1875L49.2513 48.0032C48.121 49.1461 48.1208 50.9994 49.2511 52.1425C50.3818 53.2862 52.2144 53.2854 53.3441 52.1429L60.767 44.6371C62.364 43.022 61.5924 40.2574 59.4 39.7222L55.5902 38.7918L58.2031 36.1497H94.5786C97.0165 36.1497 99 34.144 99 31.6784V4.47138C99 2.00573 97.0165 0 94.5786 0ZM93.2116 30.2959H59.8997V5.85386H93.2116V30.2959Z"
        color="#1371FB"
      />
    </IconWrapper>
  )
}
export { Benefits }
