import React from "react"
import { IconWrapper, IconPathStroke } from "shared/Styles/Icons"

const Car = ({ width, height, color }) => {
  return (
    <IconWrapper
      width={width}
      height={height}
      viewBox="0 0 132 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPathStroke
        d="M115.27 74.1265H128.961V54.977C128.961 51.9678 120.746 46.77 120.746 46.77C120.746 46.77 115.27 24.8849 104.043 24.8849H85.1484"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M41.3359 74.1265H85.1483V3H3V74.1265H19.4297"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M115.27 74.1264C115.27 80.1449 110.341 85.0691 104.316 85.0691C98.2922 85.0691 93.3633 80.1449 93.3633 74.1264C93.3633 68.1079 98.2922 63.1837 104.316 63.1837C110.341 63.1837 115.27 68.1079 115.27 74.1264Z"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M41.3359 74.1264C41.3359 80.1449 36.407 85.0691 30.3828 85.0691C24.3586 85.0691 19.4297 80.1449 19.4297 74.1264C19.4297 68.1079 24.3586 63.1837 30.3828 63.1837C36.407 63.1837 41.3359 68.1079 41.3359 74.1264Z"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M85.1484 74.1265H93.3633"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M120.746 46.7701H101.578C98.566 46.7701 96.1016 44.308 96.1016 41.2989V30.3564"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M23.8108 23.791L33.3948 33.3657L41.062 25.7059L31.478 16.1312C35.3116 12.3013 43.8002 12.8484 49.003 18.0461C52.8366 21.876 54.7533 27.0738 52.2889 32.545C51.4674 34.46 52.0151 36.6485 53.658 38.2899L70.0877 54.7038C72.0045 56.6187 72.5521 59.6279 70.9092 61.8164C68.9924 64.5521 64.885 64.8256 62.6943 62.6371L45.9909 45.9497C44.6217 44.5819 42.4311 43.7612 40.2405 44.5819C34.7639 46.7704 29.2874 44.8554 25.7276 41.2991C20.5249 36.1014 19.7034 27.8944 23.8108 23.791Z"
        color={color}
        strokeWidth="5"
        strokeMiterlimit="10"
        stroke-linejoin="round"
      />
    </IconWrapper>
  )
}

export { Car }
