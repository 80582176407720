export const GET_TEST = {
    start: 'GET_TEST_START',
    success: 'GET_TEST_SUCCESS',
    error: 'GET_TEST_ERROR',
};

export const DELETE_TEST = {
    start: 'DELETE_TEST_START',
    success: 'DELETE_TEST_SUCCESS',
    error: 'DELETE_TEST_ERROR',
};

export const ADD_TEST = {
    start: 'ADD_TEST_START',
    success: 'ADD_TEST_SUCCESS',
    error: 'ADD_TEST_ERROR',
};
