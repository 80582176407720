import styled from "styled-components"

export const GlobalWrapper = styled.div`
  width: 100%;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
  }
`
export const PageWrapper = styled.div`
  max-width: 1085px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    max-width: 860px;
    margin: 0 auto;
    padding: 0 30px;
  }
`
export const OverflowWrapper = styled.main`
  /* overflow-x: hidden; */
  position: relative;
  max-width: 100vw;
  width: 100%;
`
