import React from "react"
import { IconWrapper, IconPathStroke, IconPath } from "shared/Styles/Icons"

const Installation = ({ width, height, color }) => (
  <IconWrapper
    width={width}
    height={height}
    viewBox="0 0 115 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconPathStroke
      d="M21.3111 100.96L12.8 92.0817L27.423 78.0634L18.1609 68.4018L45.7794 68.9849L45.4465 96.8645L35.9341 86.9417L21.3111 100.96Z"
      color={color}
      strokeWidth="5"
      strokeLinejoin="round"
    />
    <IconPathStroke
      d="M100.77 93.9393L91.8919 102.45L77.8736 87.8273L68.212 97.0894L68.7951 69.471L96.6747 69.8038L86.7519 79.3162L100.77 93.9393Z"
      color={color}
      strokeWidth="5"
      strokeLinejoin="round"
    />
    <IconPathStroke
      d="M15.0521 21.7733L23.9304 13.2621L37.9486 27.8852L47.6103 18.6231L47.0271 46.2415L19.1476 45.9087L29.0704 36.3963L15.0521 21.7733Z"
      color={color}
      strokeWidth="5"
      strokeLinejoin="round"
    />
    <IconPathStroke
      d="M93.227 14.981L101.738 23.8593L87.1151 37.8776L96.3771 47.5392L68.7587 46.9561L69.0915 19.0766L78.604 28.9993L93.227 14.981Z"
      color={color}
      strokeWidth="5"
      strokeLinejoin="round"
    />
    <mask id="path-5-inside-1" fill="white">
      <IconPath d="M64.6188 65.1149C60.5349 69.0298 54.0506 68.8929 50.1357 64.8091C46.2207 60.7252 46.3576 54.2409 50.4415 50.326C54.5253 46.411 61.0096 46.5479 64.9246 50.6318C68.8395 54.7156 68.7026 61.1999 64.6188 65.1149ZM53.62 53.6416C51.3673 55.8011 51.2918 59.3779 53.4513 61.6306C55.6108 63.8832 59.1876 63.9588 61.4403 61.7992C63.693 59.6397 63.7685 56.0629 61.609 53.8103C59.4495 51.5576 55.8727 51.482 53.62 53.6416Z" />
    </mask>
    <IconPath
      d="M64.6188 65.1149C60.5349 69.0298 54.0506 68.8929 50.1357 64.8091C46.2207 60.7252 46.3576 54.2409 50.4415 50.326C54.5253 46.411 61.0096 46.5479 64.9246 50.6318C68.8395 54.7156 68.7026 61.1999 64.6188 65.1149ZM53.62 53.6416C51.3673 55.8011 51.2918 59.3779 53.4513 61.6306C55.6108 63.8832 59.1876 63.9588 61.4403 61.7992C63.693 59.6397 63.7685 56.0629 61.609 53.8103C59.4495 51.5576 55.8727 51.482 53.62 53.6416Z"
      fill={color}
      color={color}
      mask="url(#path-5-inside-1)"
    />
  </IconWrapper>
)

export { Installation }
