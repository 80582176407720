import React from "react"
import { IconWrapper, IconPathStroke } from "shared/Styles/Icons"

const FreeValuation = ({ width, height, color }) => {
  return (
    <IconWrapper
      width="128"
      height="111"
      viewBox="0 0 128 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPathStroke
        d="M63.8679 3C92.9339 3 116.715 26.565 116.715 55.3668V57.9851H122L108.788 76.3135L98.2187 57.9851H103.503V55.3668C103.503 33.6346 85.7996 16.0917 63.8679 16.0917C49.8633 16.0917 37.4442 23.423 30.3098 34.4201"
        color="#1371FB"
        strokeWidth="5.18727"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M37.4443 10.0693C35.5946 11.1167 33.745 12.4258 32.1595 13.4732"
        color="#1371FB"
        strokeWidth="5.18727"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M47.7494 5.35645C45.8997 5.88011 44.3143 6.66561 42.7289 7.18928"
        color="#1371FB"
        strokeWidth="5.18727"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M58.5831 3.26196C56.9977 3.5238 55.148 3.78563 53.5626 4.04746"
        color="#1371FB"
        strokeWidth="5.18727"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M63.8679 107.733C34.8018 107.733 11.2847 84.1683 11.2847 55.3666V52.7483H6L19.2118 34.4199L29.7813 52.7483H24.4966V55.3666C24.4966 77.0988 41.672 94.6417 63.6036 94.6417C77.6082 94.6417 90.0273 87.3104 97.1617 76.3133"
        color="#1371FB"
        strokeWidth="5.18727"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M90.2916 100.664C92.1413 99.6168 93.9909 98.3076 95.5764 97.2603"
        color="#1371FB"
        strokeWidth="5.18727"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M79.9863 105.377C81.836 104.854 83.4214 104.068 85.0068 103.544"
        color="#1371FB"
        strokeWidth="5.18727"
        strokeMiterlimit="10"
      />
      <IconPathStroke
        d="M69.1526 107.472C70.738 107.21 72.5877 106.948 74.1731 106.686"
        color="#1371FB"
        strokeWidth="5.18727"
        strokeMiterlimit="10"
      />
    </IconWrapper>
  )
}
export { FreeValuation }
