export const defaultTheme = {
  color: {
    black: "#000",
    lightBlack: "#4A4A44",
    gray: "#D7D7D7",
    primary: "#1371FB",
    primaryDark: "#126BED",
    secondary: "#FFBA33",
    grayText: "#7A7A7A",
    white: "#FAFAFA",
    darkGray: "#929292",
  },
  font: {
    desktop: {
      h1: {
        size: "90px",
        weight: 700,
      },
      h2: {
        size: "32px",
        weight: 700,
      },
      h3: {
        size: "24px",
        weight: {
          regular: 400,
          bold: 700,
        },
      },
      h4: {
        size: "18px",
        weight: {
          regular: 400,
          bold: 800,
        },
      },
      h5: {
        size: "16px",
        weight: {
          regular: 400,
          bold: 700,
        },
      },
      link: {
        size: "18px",
        weight: {
          extrabold: 900,
        },
      },
    },
    mobile: {
      h1: {
        size: "40px",
        weight: 700,
      },
      h2: {
        size: "36px",
        weight: 700,
      },
      h3: {
        size: "22px",
        weight: {
          regular: 400,
          bold: 700,
        },
      },
      h4: {
        size: "14px",
        weight: 700,
      },
      h5: {
        size: "16px",
        weight: {
          regular: 400,
          bold: 800,
        },
      },
    },
    tablet: {
      h1: {
        size: "60px",
        weight: 700,
      },
      h2: {
        size: "32px",
        weight: 700,
      },
      h3: {
        size: "20px",
        weight: {
          regular: 400,
          bold: 700,
        },
      },
      h4: {
        size: "14px",
        weight: 700,
      },
      h5: {
        size: "16px",
        weight: {
          regular: 400,
          bold: 800,
        },
      },
    },
  },
  breakpoints: {
    phoneSmall: "374px",
    phoneBig: "601px",
    tabletSmall: "864px",
    tablet: "1025px",
    tabletBig: "1159px",
    laptopSmall: "1281px",
    laptopStandard: "1441px",
    laptopBig: "1601px",
  },
}
