import styled from "styled-components"

const BigSubpageTitle = styled.h1`
  font-size: ${props => props.theme.font.desktop.h2.size};
  font-weight: ${props => props.theme.font.desktop.h2.weight};
  color: ${props => (props.color ? props.color : props.theme.color.black)};
  line-height: 1.2;
`

export { BigSubpageTitle }
