import { GET_TEST, DELETE_TEST, ADD_TEST } from "./consts"

export const initialState = {
  testList: [],
  isPending: false,
}

export const testList = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TEST.start:
    case DELETE_TEST.start:
    case ADD_TEST.start:
      return {
        ...state,
        isPending: true,
      }
    case GET_TEST.success:
      return {
        isPending: false,
        ...state,
      }
    case ADD_TEST.success:
      return {
        isPending: false,
        testList: [...state.testList, payload],
      }
    case GET_TEST.error:
    case DELETE_TEST.error:
    case ADD_TEST.error:
      return {
        ...state,
        isPending: false,
      }
    case DELETE_TEST.success:
      return {
        testList: state.testList.filter(({ id }) => id !== payload),
        isPending: false,
      }
    default:
      return state
  }
}
