import React from "react"
import { createStore, applyMiddleware } from 'redux';
import { Provider } from "react-redux"
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { rootReducer } from './rootReducer';

const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(thunkMiddleware),
));

export default ({element}) => (<Provider store={store}>{element}</Provider>)