import React from "react"
import { IconWrapper, IconPath } from "shared/Styles/Icons/index.js"

export const EmailIcon = ({color}) => {
  return (
    <IconWrapper
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPath
        d="M19.7909 0.386719C9.2758 0.386719 0.766113 8.9143 0.766113 19.4532C0.766113 29.9132 9.19858 38.6692 19.7909 38.6692C30.284 38.6692 38.9649 30.0023 38.9649 19.4532C38.9649 8.82607 30.2167 0.386719 19.7909 0.386719ZM19.7909 36.4411C10.5348 36.4411 2.9894 28.8121 2.9894 19.4532C2.9894 10.1768 10.5348 2.61488 19.7909 2.61488C29.1293 2.61488 36.7417 10.1768 36.7417 19.4532C36.7417 28.8121 29.1293 36.4411 19.7909 36.4411Z"
        fill="white"
        color={color}
      />
      <IconPath
        d="M19.6422 6.89172C12.8544 6.89172 7.33203 12.4262 7.33203 19.2289C7.33203 26.0571 12.843 31.7155 19.6422 31.7155C22.3933 31.7155 25.1762 30.7889 27.2774 29.1733C27.7678 28.7962 27.8603 28.0922 27.4841 27.6007C27.1078 27.1092 26.4053 27.0165 25.9149 27.3936C24.1968 28.7147 21.9105 29.4874 19.6422 29.4874C14.0885 29.4874 9.57024 24.8772 9.57024 19.2289C9.57024 13.663 14.0885 9.11989 19.6422 9.11989C25.2782 9.11989 29.8634 13.663 29.8634 19.2289V20.3504C29.8634 21.5873 28.8593 22.5935 27.6252 22.5935C26.391 22.5935 25.3869 21.5873 25.3869 20.3504C25.3869 19.7949 25.3869 15.3285 25.3869 14.7426C25.3869 14.1232 24.8859 13.6211 24.2678 13.6211C23.6498 13.6211 23.1487 14.1232 23.1487 14.7426V14.8137C22.1381 14.0414 20.9156 13.6211 19.6422 13.6211C16.5568 13.6211 14.0467 16.1367 14.0467 19.2289C14.0467 22.321 16.5568 24.8367 19.6422 24.8367C21.3151 24.8367 22.8547 24.1172 23.9496 22.9067C24.759 24.0719 26.1043 24.8367 27.6252 24.8367C30.0935 24.8367 32.1016 22.8241 32.1016 20.3504V19.2289C32.1016 12.4058 26.4471 6.89172 19.6422 6.89172ZM19.6422 22.6085C17.791 22.6085 16.2849 21.0841 16.2849 19.2289C16.2849 17.3736 17.791 15.8492 19.6422 15.8492C21.543 15.8492 23.1487 17.405 23.1487 19.2289C23.1487 21.0527 21.543 22.6085 19.6422 22.6085Z"
        fill="white"
        color={color}
      />
    </IconWrapper>
  )
}
