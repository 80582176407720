import styled from "styled-components"

const SubpageTitle = styled.h1`
  font-size: ${props => props.theme.font.desktop.h3.size};
  font-weight: ${props => props.theme.font.desktop.h3.weight.regular};
  color: ${props => (props.color ? props.color : props.theme.color.black)};
  line-height: 1.3;
  padding-left: 90px;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 13px;
    left: 0;
    height: 2px;
    width: 47px;
    background-color: ${props =>
      props.white ? "white" : props.theme.color.black};
  }
`

export { SubpageTitle }
