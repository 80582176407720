import React from "react"
import { IconWrapper, IconPath } from "shared/Styles/Icons"

const TechHelp = ({ width, height, color }) => {
  return (
    <svg
      width="121"
      height="139"
      viewBox="0 0 121 139"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110.529 18.0435H10.4712C4.69726 18.0435 0 23.4395 0 30.0721V68.8317C0 75.4643 4.69726 80.8606 10.4712 80.8606H110.529C116.303 80.8606 121 75.4646 121 68.8317V30.0721C121 23.4395 116.303 18.0435 110.529 18.0435ZM22.9784 72.8415L25.5962 59.4761H95.404L98.0218 72.8415H22.9784ZM114.019 68.8317C114.019 71.0426 112.454 72.8412 110.529 72.8412H105.177L101.601 54.5863C101.243 52.7565 99.8285 51.4567 98.1962 51.4567H22.8038C21.1712 51.4567 19.7568 52.7565 19.3985 54.5863L15.8229 72.8412H10.4712C8.54657 72.8412 6.9809 71.0426 6.9809 68.8317V30.0721C6.9809 27.8611 8.54657 26.0626 10.4712 26.0626H110.529C112.454 26.0626 114.019 27.8611 114.019 30.0721V68.8317Z"
        fill="#1371FB"
      />
      <path
        d="M79.1153 38.0916H74.4616C72.5338 38.0916 70.9713 39.8869 70.9713 42.1011C70.9713 44.3153 72.5341 46.1107 74.4616 46.1107H79.1153C81.0431 46.1107 82.6057 44.3153 82.6057 42.1011C82.6057 39.8869 81.0431 38.0916 79.1153 38.0916Z"
        fill="#1371FB"
      />
      <path
        d="M95.4038 38.0916H90.75C88.8223 38.0916 87.2597 39.8869 87.2597 42.1011C87.2597 44.3153 88.8225 46.1107 90.75 46.1107H95.4038C97.3315 46.1107 98.8941 44.3153 98.8941 42.1011C98.8941 39.8869 97.3315 38.0916 95.4038 38.0916Z"
        fill="#1371FB"
      />
      <path
        d="M62.8269 99.1256C62.8269 98.009 63.0081 97.6618 63.4928 96.7341C64.1543 95.4679 65.1537 93.5545 65.1537 90.2163C65.1537 88.0018 63.5909 86.2068 61.6634 86.2068C59.7359 86.2068 58.1731 88.0021 58.1731 90.2163C58.1731 91.333 57.9918 91.6802 57.5071 92.6078C56.8456 93.874 55.8462 95.7875 55.8462 99.1256C55.8462 102.464 56.8456 104.378 57.5069 105.644C57.9916 106.572 58.1731 106.919 58.1731 108.036C58.1731 109.153 57.9918 109.501 57.5069 110.429C56.8456 111.695 55.8462 113.609 55.8462 116.947C55.8462 119.161 57.409 120.957 59.3365 120.957C61.264 120.957 62.8269 119.161 62.8269 116.947C62.8269 115.83 63.0081 115.482 63.4931 114.555C64.1543 113.288 65.1537 111.375 65.1537 108.036C65.1537 104.698 64.1543 102.784 63.4931 101.518C63.0084 100.59 62.8269 100.243 62.8269 99.1256Z"
        fill="#1371FB"
      />
      <path
        d="M44.2115 99.1256C44.2115 98.009 44.3928 97.6618 44.8775 96.7341C45.539 95.4679 46.5384 93.5545 46.5384 90.2163C46.5384 88.0018 44.9755 86.2068 43.0481 86.2068C41.1206 86.2068 39.5577 88.0021 39.5577 90.2163C39.5577 91.333 39.3765 91.6802 38.8917 92.6078C38.2303 93.874 37.2308 95.7875 37.2308 99.1256C37.2308 102.464 38.2303 104.378 38.8915 105.644C39.3762 106.572 39.5577 106.919 39.5577 108.036C39.5577 109.153 39.3765 109.501 38.8915 110.429C38.2303 111.695 37.2308 113.609 37.2308 116.947C37.2308 119.161 38.7937 120.957 40.7212 120.957C42.6487 120.957 44.2115 119.161 44.2115 116.947C44.2115 115.83 44.3928 115.482 44.8777 114.555C45.5389 113.288 46.5384 111.375 46.5384 108.036C46.5384 104.698 45.5389 102.784 44.8777 101.518C44.393 100.59 44.2115 100.243 44.2115 99.1256Z"
        fill="#1371FB"
      />
      <path
        d="M81.4423 99.1256C81.4423 98.009 81.6235 97.6618 82.1082 96.7341C82.7697 95.4679 83.7692 93.5545 83.7692 90.2163C83.7692 88.0018 82.2063 86.2068 80.2788 86.2068C78.3513 86.2068 76.7885 88.0021 76.7885 90.2163C76.7885 91.333 76.6072 91.6802 76.1225 92.6078C75.461 93.874 74.4616 95.7875 74.4616 99.1256C74.4616 102.464 75.461 104.378 76.1223 105.644C76.607 106.572 76.7885 106.919 76.7885 108.036C76.7885 109.153 76.6072 109.501 76.1223 110.429C75.461 111.695 74.4616 113.609 74.4616 116.947C74.4616 119.161 76.0244 120.957 77.9519 120.957C79.8794 120.957 81.4423 119.161 81.4423 116.947C81.4423 115.83 81.6235 115.482 82.1085 114.555C82.7697 113.288 83.7692 111.375 83.7692 108.036C83.7692 104.698 82.7697 102.784 82.1085 101.518C81.6238 100.59 81.4423 100.243 81.4423 99.1256Z"
        fill="#1371FB"
      />
    </svg>
  )
}
export { TechHelp }
