import React from "react"
import { IconWrapper, IconPath } from "shared/Styles/Icons/index.js"

const PhoneIcon = ({ width, height, color }) => {
  return (
    <IconWrapper
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPath
        d="M36.9967 28.2327L32.373 23.5988C31.0982 22.3214 29.0239 22.3214 27.7492 23.5988L25.6476 25.7051C24.6819 26.6729 23.1104 26.6727 22.1451 25.7055L13.0355 16.5682C12.0676 15.5981 12.0674 14.0279 13.0355 13.0577C13.3741 12.7184 14.3461 11.7443 15.1372 10.9514C16.4064 9.67956 16.4241 7.60723 15.136 6.31643L10.5135 1.69839C9.23874 0.420919 7.16454 0.420919 5.8932 1.69504C4.94186 2.64017 4.63501 2.94507 4.20844 3.36886C-0.388455 7.97576 -0.388456 15.4718 4.20823 20.0785L18.642 34.5515C23.2496 39.1693 30.7072 39.1696 35.3152 34.5515L36.9967 32.8664C38.2714 31.5889 38.2714 29.5102 36.9967 28.2327ZM7.43107 3.24298C7.85596 2.81716 8.54724 2.81708 8.97351 3.24414L13.596 7.86218C14.0219 8.28903 14.0219 8.97985 13.596 9.40677L12.8253 10.1791L6.66439 4.00464L7.43107 3.24298ZM20.1835 33.0071L5.74965 18.534C2.19316 14.9697 1.99768 9.37299 5.14846 5.57464L11.2915 11.7312C9.67736 13.565 9.74463 16.3594 11.494 18.1126L20.6032 27.2494C20.6033 27.2495 20.6034 27.2496 20.6035 27.2497C22.3509 29.001 25.1391 29.0728 26.9712 27.453L33.1144 33.6096C29.3364 36.7618 23.7644 36.5959 20.1835 33.0071ZM35.4555 31.3218L34.6848 32.0942L28.5199 25.9157L29.2905 25.1434C29.7154 24.7176 30.4068 24.7175 30.8317 25.1434L35.4554 29.7773C35.8804 30.2032 35.8804 30.8961 35.4555 31.3218Z"
        fill="white"
        color={color}
      />
    </IconWrapper>
  )
}

export { PhoneIcon }
