import styled from "styled-components"
import { Link } from "gatsby"
import { OverflowWrapper, PageWrapper } from "shared/Styles/Wrappers"
import { FooterLink } from "components/Buttons"
import { MediumHeader, BigHeader } from "components/Typography"

export const FooterWrapper = styled(OverflowWrapper)`
  margin-top: 130px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    margin-top: 100px;
  }
`

export const FooterTop = styled(PageWrapper)`
  position: relative;
  z-index: 2;
`

export const FooterCTA = styled.div`
  max-width: 330px;
  margin-bottom: 46px;
  margin-left: 80px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    max-width: 220px;
    ${MediumHeader} {
      font-size: 20px;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    max-width: 330px;
    margin: 0 0 40px 40px;
    ${MediumHeader}:first-child {
      font-size: 36px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    margin: 0 0 30px 0;
  }
`
export const BackgroundLogo = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 10%;
  z-index: 1;
  overflow: hidden;
  svg {
    height: 130%;
    width: auto;
    path {
      fill: #126bed;
    }
  }
`
export const FooterContact = styled.div`
  width: 530px;
  padding: 70px 80px;
  height: auto;
  position: absolute;
  z-index: 12;
  right: 80px;
  top: 0;
  background: ${({ theme }) => theme.color.primary};
  color: white;
  overflow: hidden;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopStandard}) {
    width: 450px;
    padding: 35px 40px;
    h2 {
      font-size: 55px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    width: 330px;
    padding: 50px 40px;
    ${BigHeader} {
      font-size: 50px;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    position: relative;
    margin: auto 0 auto -30px;
    width: calc(100% + 60px);
    padding: 40px 30px 23px 70px;
    right: unset;
    ${BigHeader} {
      font-size: 36px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    padding-left: 30px;
  }

  & > *:not(${BackgroundLogo}) {
    z-index: 2;
    position: relative;
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    height: 26px;
    background: ${({ theme }) => theme.color.secondary};
    width: 100%;
    z-index: 2;
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.laptopStandard}) {
      height: 18px;
    }
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tabletSmall}) {
      height: 10px;
    }
  }
`

export const DirectContactWrapper = styled.div`
  display: flex;
`

export const DirectContact = styled.a`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  padding: 30px 40px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.3s color ease-in-out;
  color: inherit;

  svg {
    margin-right: 40px;
    path {
      fill: white;
      transition: 0.3s fill ease-in-out;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopStandard}) {
    font-size: 16px;
    padding: 20px 20px 20px 0;
    svg {
      margin-right: 40px;
      width: 30px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    font-size: 14px;
    padding: 20px;
    svg {
      margin-right: 20px;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    padding: 15px 0;
    svg {
      width: 38px;
      height: 38px;
    }
  }
  &:hover {
    color: ${({ theme }) => theme.color.secondary};
    svg path {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }
`

export const MapWrapper = styled.div`
  width: 100%;
  height: 550px;
  position: relative;
  z-index: 1;
  & > div {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    height: 400px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    height: 350px;
  }
`
export const FooterNav = styled.div`
  background: ${({ theme }) => theme.color.secondary};
`
export const FooterNavLinksContainer = styled(PageWrapper)`
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
  }
  ${FooterLink} {
    padding: 90px 0;
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tabletBig}) {
      padding: 60px 0;
    }
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.phoneBig}) {
      width: 33%;
      height: 100px;
      padding: unset;
      line-height: 100px;
      text-align: center;
    }
  }
`
export const FacebookLink = styled(FooterLink)`
  background: ${({ theme }) => theme.color.primary};
  width: 130px;
  text-align: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    width: 90px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    width: 33%;
    font-size: 20px;
  }
`
export const FooterLogo = styled.a`
  height: 52px;
  margin-right: 40px;
  margin-left: 10px;
  svg {
    height: 100%;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    height: 40px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    margin-right: 0;
    height: 28px;
    &:first-child {
      display: none;
    }
  }
`
export const FooterBottom = styled(PageWrapper)`
  display: flex;
  align-items: center;
  padding: 50px 30px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    justify-content: center;
  }
`
