import styled from "styled-components"

const Paragraph = styled.p`
  font-size: ${props => props.theme.font.desktop.h5.size};
  color: ${props => (props.color ? props.color : props.theme.color.grayText)};
  line-height: 24px;
  font-weight: ${props =>
    props.bolded
      ? props.theme.font.desktop.h3.weight.bold
      : props.theme.font.desktop.h3.weight.regular};
`

export { Paragraph }
